@use '@carbon/react/scss/colors';


.billing-page {
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  width: 100%;

  .membership-features {
    ul {
      list-style-type: disc; // Add bullet points
      padding-left: 1.5rem; // Add space for bullets
      margin: 0.5rem 0;

      li {
        color: colors.$gray-100;
        margin-bottom: 0.5rem;
        line-height: 1.5;
        
        // Add custom bullet color if needed
        &::marker {
          color: colors.$blue-60; // Use Carbon's blue color
        }
      }
    }

    // Style lists in accordion similarly
    .value-props ul {
      list-style-type: disc;
      padding-left: 1.5rem;
      
      li::marker {
        color: colors.$blue-60;
      }
    }
  }
}

.billing-container {
  width: 100%;
  max-width: 1424px; // Increase max-width
  padding: 0 1rem;
}

// Keep other styles the same...

.billing-page {
  width: 100%;
  padding: 2rem 1rem;
}

.billing-container,
.membership-features {
  width: 100%;
  /* Remove max-width to let them expand */
  margin: 0 auto;
}

.form-item {
  margin-bottom: 1rem;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

.button-group > .bx--btn {
  flex: 1 1 auto;
}

/* For smaller screens, keep some horizontal padding */
@media screen and (max-width: 768px) {
  .billing-container,
  .membership-features {
    padding: 0 1rem;
  }
}
