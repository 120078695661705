@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/react/scss/theme' as *;
@use './mixins.scss' as *;
@use './overrides.scss';

button.sb-tooltip-trigger {
  border: 0;
}

.llm-submit-button {
  margin-top: 10px; // Keeps the existing space above the button
  margin-bottom: 20px; // Increases space below the button
  font-size: 0.9em; // Keeps the smaller text size
  padding: 4px 12px; // Reduces vertical padding to decrease button height
}

.llm-request-textarea {
  width: 100%;
  min-height: 90px;
  font-size: 16px;
  // white-space: pre-wrap; (uncomment if needed)
}

div.llm-response-area {
  padding-top: 30px;
}


.llm-response-area {
  position: relative;
  min-height: 100px;
}

.loading-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

// CSS
.typing-indicator {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: #f3f3f3;
  border-radius: 1.5rem;
  width: fit-content;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 3px;
  background: #666;
  border-radius: 50%;
  animation: typing 1.4s infinite ease-in-out;
}

.dot:nth-child(2) { animation-delay: 0.2s; }
.dot:nth-child(3) { animation-delay: 0.4s; }

@keyframes typing {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

/* Code block container */
.code-block-container {
  background: #000;
  border-radius: 8px;
  margin: 1rem 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Header section */
.code-block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: #1a1a1a;
  border-bottom: 1px solid #333;
  border-radius: 8px 8px 0 0;
}

.language-label {
  color: #888;
  font-size: 0.85em;
  font-family: 'Fira Code', monospace;
}

/* Copy button */
.copy-button {
  background: none;
  border: 1px solid #444;
  color: #888;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Fira Code', monospace;
  transition: all 0.2s ease;
}

.copy-button:hover {
  background: #333;
  color: #fff;
  border-color: #666;
}

/* Syntax highlighter adjustments */
.syntax-highlighter {
  background: #000 !important;
  padding: 16px !important;
  border-radius: 0 0 8px 8px;
  font-size: 0.9em;
  line-height: 1.5;
}

/* Inline code styling */
.inline-code {
  background: rgba(255, 255, 255, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
  color: #e06c75;
  font-family: 'Fira Code', monospace;
}

.landing-page {
  &__illo {
    max-width: 100%;
  }

  &__banner,
  &__r2,
  &__r3 {
    padding-top: $spacing-06;
    padding-bottom: $spacing-06;
    padding-left: $spacing-06;
    margin-left: -20px;
    margin-right: -20px;

    @include landing-page-background;

    @include breakpoint-down(md) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__banner {
    padding-bottom: 1px;
    padding-top: 1px;
  }

  &__heading {
    @include type-style('productive-heading-05');
    margin-bottom: 1px; // Reduced bottom margin for the heading
  }

  &__r2 {
    //margin-top: rem(-40px);
  }

  &__subheading,
  &__p {
    @include type-style('productive-heading-03');
    margin-top: $spacing-06;
    margin-bottom: $spacing-08;
  }

  &__subheading {
    font-weight: 600;
    margin-top: 1px; // Reduced top margin for the subheading
  }

  &__p {
    @include breakpoint-between((320px + 1), md) {
      max-width: 95%;
    }
  }

  &__label {
    @include type-style('heading-01');
  }
}

div.accordion-top-space {
  margin-top: 1.5em;
  margin-bottom: 2em;
}

div.tabs-group-content {
  padding: 1rem; //This is padding around
}

.login-button {
  margin-bottom: 1em;
}

.llm-select {
  margin-bottom: 1em;
  font-size: 16px;
}

.llm-submit {
  margin-top: 1em;
}

.tabs-group {
  background-color: $layer-01;

  &-content {
    padding: $spacing-10 $spacing-06;
  }
}

.use-tools-container {
  display: flex;
  flex-direction: row;
  align-items: baseline; /* Align items based on text baseline */
  flex-wrap: wrap;
  gap: 8px;
}

.sb-tooltip-trigger {
  margin-left: 4px;
}

.use-tools-container input,
.use-tools-container label,
.use-tools-container .bx--list-box__field {
  line-height: 1.5; /* Ensure consistent line-height */
}

/* Plaintext styling */
.plaintext-pre {
  margin: 1em 0;
  padding: 0;
  background: none;
  border: none;
  white-space: pre-wrap;
}

.plaintext-code {
  font-family: inherit; /* Use document font */
  color: inherit; /* Use document text color */
  background: none;
  padding: 0;
  border: none;
  white-space: pre-wrap;
}

/* Regular code blocks keep their styling */
.code-block-container {
  background: #000;
  border-radius: 8px;
  margin: 1rem 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.llm-response-area {
  ul,
  ol {
    margin-left: $spacing-05;
    padding-left: $spacing-05;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-bottom: $spacing-03;
    line-height: 1.5;

    // Nested list spacing
    > ul,
    > ol {
      margin-top: $spacing-03;
      margin-bottom: $spacing-03;
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
    margin: $spacing-05 0;
    border: 1px solid $border-strong;
  }

  th,
  td {
    border: 1px solid $border-strong;
    padding: $spacing-03 $spacing-04;
    text-align: left;
  }

  th {
    background-color: $layer-accent;
    font-weight: 600;
  }

  tr:nth-child(even) {
    background-color: $layer-01;
  }

  tr:hover {
    background-color: $layer-hover;
  }

  table {
    @include type-style('body-compact-01');
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  }

  th {
    @include type-style('heading-01');
  }  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    margin: 1.5rem 0 0.75rem;
    line-height: 1.25;
    color: $text-primary;
  }

  h1 {
    font-size: 1.5rem !important;  // Equivalent to Carbon's heading-03
    margin-top: 2rem;
  }

  h2 {
    font-size: 1.375rem !important;  // Slightly smaller than h1
  }

  h3 {
    font-size: 1.25rem !important;
  }

  h4 {
    font-size: 1.125rem !important;
  }

  h5 {
    font-size: 1rem !important;
  }

  h6 {
    font-size: 0.875rem !important;
    color: $text-secondary;
  }

  // Ensure these styles apply to accordion content too
  .accordion-top-space & {
    @extend .llm-response-area;
  }



}

.accordion-top-space {
  .llm-response-area {
    @extend .llm-response-area; // Inherits the same list styles
  }
}

//Model selection

/* Model Selection Grid */
.model-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  margin-bottom: 2rem;
}

.model-card {
  padding: 1.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: white;
}

.model-card:hover {
  border-color: #007d79;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.model-card.selected {
  border-color: #007d79;
  background-color: rgba(0, 125, 121, 0.05);
}

.model-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.model-description {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 0.75rem;
}

.model-strengths {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.model-tag {
  background: #e0e0e0;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75em;
  color: #333;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
}

/* Compact Model Selector */
.model-selector {
  display: inline-block;
  margin-right: 1rem;
}

.current-model {
  text-align: left;
  max-width: 240px;
}

.current-model strong {
  display: block;
  font-weight: 600;
}

.current-model .model-description {
  display: block;
  font-size: 0.8em;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.model-selector-icon {
  margin-left: 0.5rem;
}

/* Model Modal */
.model-selector-modal .bx--modal-container {
  max-width: 800px;
}

.model-modal-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  max-height: 60vh;
  overflow-y: auto;
  margin-top: 1rem;
  padding-right: 1rem;
}

.model-search {
  margin-bottom: 1.5rem;
}

/* Compact Model Cards */
.model-card {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: white;
}

.model-card h5 {
  margin: 0;
  font-size: 1em;
}

.model-card .model-description {
  font-size: 0.8em;
  margin: 0.5rem 0;
}

.history-actions {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start; 
  padding: 0.5rem 0;
  width: 100%;
  
  // Remove any existing margin-left: auto
  .bx--btn {
    margin-left: 0;
  }
}

.model-meta {
  margin-top: 1rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e0e0e0;
  color: #666;
}

.model-meta {
  margin-top: 1rem;
  color: #666;
  font-size: 0.875rem;
}

/* Toolbar Layout 
.toolbar-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.toolbar-section {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  
  // Responsive breakpoint
  @include breakpoint-down(md) {
    gap: 1rem;
  }
}
*/

.toolbar-section {
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
  flex-wrap: wrap;
  
  @include breakpoint-down(md) {
    gap: 1rem;
  }
}
/*
.tools-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  
  .tool-toggle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
  }
}
  */

.tools-container {
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  flex: 1;
  min-width: 300px;
  
  @include breakpoint-down(md) {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }
}


// Add to your stylesheet
.bx--tooltip__content {
  white-space: normal !important;
  max-width: 300px !important;
  text-align: left !important;
}

// If using Carbon's default tooltip
.bx--tooltip--definition .bx--tooltip__content {
  white-space: normal;
  max-width: 300px;
}

// For your custom tooltips
.sb-tooltip-trigger + .bx--tooltip__content {
  white-space: normal !important;
  max-width: min(300px, 90vw) !important;
}

// Remove the old .toolbar-right styles
.model-parameters {
  margin: 1rem 0;
  border-top: 1px solid $border-subtle;
  padding-top: 1rem;
}

.parameter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 0.75rem;
}

.parameter-item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background: $layer-01;
  border-radius: 4px;
}

.parameter-label {
  font-size: 0.75em;
  color: $text-secondary;
  margin-bottom: 0.25rem;
}

.parameter-value {
  font-size: 0.9em;
  font-weight: 500;
  color: $text-primary;
  
  // For the checkmarks/x's
  &.icon-indicator {
    font-size: 1.2em;
  }
}

.modality-tags {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.modality-tag {
  background: rgba(100, 149, 237, 0.1); // Cornflower blue
  color: #6495ed;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75em;
}

/* Model Attributes */
.model-attributes {
  display: grid;
  gap: 1rem;
  margin: 1rem 0;

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Parameters Heading */
.parameters-heading {
  margin: 1.5rem 0 1rem;
  font-size: 1rem;
  color: $text-primary;
  border-bottom: 1px solid $border-subtle;
  padding-bottom: 0.5rem;
}

/* Parameter Grid Adjustments */
.parameter-grid {
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
}

/* Modality Tag Enhancements */
.modality-tag {
  text-transform: capitalize;
  font-weight: 500;
  
  &[data-modality="text"] { background: rgba(87, 160, 255, 0.1); color: #57a0ff; }
  &[data-modality="image"] { background: rgba(76, 175, 80, 0.1); color: #4CAF50; }
  &[data-modality="audio"] { background: rgba(156, 39, 176, 0.1); color: #9C27B0; }
}

.model-modal-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  .bx--toggle-input:checked + .bx--toggle__switch {
    background-color: $interactive;
  }
}

.specs-toggle {
  flex-shrink: 0;
  
  .bx--toggle__text--left,
  .bx--toggle__text--right {
    @include type-style('body-short-01');
  }
}

// Add to your styles
.bx--toggle-input:focus + .bx--toggle__switch {
  box-shadow: none;
}
// Add these styles to match strengths
.model-limitations {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;

  .model-tag {
    background: rgba(255, 87, 87, 0.1); // Slightly different color for limitations
    border: 1px solid rgba(255, 87, 87, 0.3);
    color: #ff5757;
  }
}

// Update existing strengths styling to be more specific
.model-strengths {
  .model-tag {
    background: rgba(0, 125, 121, 0.1); // Carbon teal color
    border: 1px solid rgba(0, 125, 121, 0.3);
    color: #007d79;
  }
}

// Base tag styles (keep existing)
.model-tag {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75em;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.tool-toggle-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 0.5rem;
  
  .bx--toggle__label-text {
    padding-right: 0.5rem;
  }
}

.tools-select {
  flex: 1;
  min-width: 350px;
  max-width: 400px;
  
  .bx--list-box__menu-icon {
    margin-right: $spacing-03;
  }
  
  .bx--label {
    font-weight: 600;
    color: $text-primary;
    margin-bottom: $spacing-02;
  }

  @include breakpoint-down(md) {
    min-width: 100%;
    max-width: 100%;
  }
}

.tooltip-button {
  padding: 0 !important;
  margin-left: -0.5rem;
}